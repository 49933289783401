import 'bootstrap';

const axios = require('axios');

let form = document.getElementById('signupform');
if(form) {
  let formFields = form.getElementsByTagName("input");
  let data = {}

  form.addEventListener('submit', function(e) {
    e.preventDefault();

    document.getElementById('submit').classList.add('disabled');
    document.getElementById('submitting').classList.remove('d-none');

    if (!form.checkValidity()) {
      e.preventDefault()
      e.stopPropagation()
    }

    // grecaptcha.ready(function() {
    //     grecaptcha.execute('6LcflX8bAAAAANAZswxUn3A5f2qeOkwuY4pu0PVJ', {action: 'demo'})
    //         .then(function(token) {
    // // this token will be passed together with your data
    //         });
    // });

    form.classList.add('was-validated')

    for (var i = 0; i < formFields.length; i++) {
      data[formFields[i].name] = formFields[i].value
    }

    axios({
      method: 'post',
      url: form.getAttribute('action'),
      data: data,
      dataType:'json'
    }).then(function (response) {
        // handle success
        window.location.href = '/thankyou';
      })
      .catch(function (error) {
        // handle error
          window.location.href = '/confirmed';
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }, false);
}
